.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.login_input {
  background-color: rgba(256, 256, 256, 0.3);
  color: white;
  outline: none;
  border: none;
  font-size: 20px;
}

.login_input:focus {
  border: 1px solid #d1ae6e !important;
}

.login_input::placeholder {
  color: rgba(256, 256, 256, 0.5);
  font-size: 15px;
}

.hover_underline {
  text-decoration: none;
}

.hover_underline:hover {
  text-decoration: underline;
}

.gc_button {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.gc_button:hover {
  cursor: pointer;
  opacity: 0.9;
  scale: 1.02;
  transition: all 0.3s ease-in-out;
}

.menu_item {
  color: white;
  background-color: transparent;
  cursor: pointer;
}

.menu_item:hover {
  color: #282828;
  background-color: rgba(256, 256, 256, 0.8) !important;
}

.menu_item:hover .mui_menu_icon {
  color: #282828;
}

.invoice_item {
  background-color: white;
  cursor: pointer;
}
.invoice_item:hover {
  background-color: #f7f6f5 !important;
}

.search_input {
  background-color: rgba(256, 256, 256, 0.3);
  color: rgb(48, 48, 48);
  border: 1px solid lightgrey;
  outline: none;
}

.search_input:focus {
  border: 1px solid #d1ae6e !important;
}

.search_input::placeholder {
  color: rgb(172, 172, 172);
}
.remove_text_decoration {
  text-decoration: none !important;
}

.blink {
  animation: blink-animation 1.5s infinite;
}

@keyframes blink-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

.close_icon {
  rotate: 0deg;
  scale: 1;
  transition: all 0.3s ease-in-out;
}
.close_icon:hover {
  rotate: 90deg;
  scale: 1.2;
  transition: all 0.3s ease-in-out;
}

.show_password {
  opacity: 0;
  transition: all 2s ease-in-out;
}

.password_field:hover .show_password {
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

.client_select_dropdown {
  color: #282828;
  cursor: pointer;
  border-radius: 6px;
  background-color: white;
}

.client_select_dropdown:hover {
  color: white;
  background-color: #d1ae6e;
}

.client_select_dropdown:hover .client_select_dropdown_text{
  color: white !important;
}
.remove_quote_product {
  rotate: 0deg;
  transition: 0.3s all ease-in-out;
  color: lightgrey;
}

.remove_quote_product:hover {
  rotate: 180deg;
  transition: 0.3s all ease-in-out;
  color: red;
}

.remove_invoice_product {
  rotate: 0deg;
  transition: 0.3s all ease-in-out;
  color: lightgrey;
}

.remove_invoice_product:hover {
  rotate: 180deg;
  transition: 0.3s all ease-in-out;
  color: red;
}

.dash_main .dash_main_text {
  scale: 1;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
}

.dash_main {
  cursor: pointer;
  background-color: #282828 !important;
}

.dash_main:hover {
  cursor: pointer;
  background-color: #303030 !important;
}

.dash_main:hover .dash_main_text {
  scale: 1.05;
  transition: 0.3s all ease-in-out;
}

.reset_password_button {
  cursor: pointer;
  color: #b5b5b5 !important;
  border: 1px solid #b5b5b5 !important;
}

.reset_password_button:hover {
  cursor: pointer;
  color: red !important;
  border: 1px solid red !important;
}

.reset_password_submit:disabled {
  background-color: grey !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}


/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  z-index: 5001 !important;
  width: 20px;
  height: 20px;
  right: 36px;
  top: 10px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #ffff;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #ffff;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #ffff;
  color: #ffff;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  top: 5vh;
}

/* General sidebar styles */
.bm-menu {
  background: #ffff;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #ffff;
}

/* Individual item */
/* .bm-item {
  display: inline-block;
} */

/* Styling of overlay */
.bm-overlay {
  background: transparent !important;
}